.em {
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: large;
}

.play{
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: small;
}

.record{
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: small;
}