.Matrix {
  text-align: center;
  align-items: center;
  width: fit-content;
  height:fit-content;
  display: flex;
  cursor: pointer;
  border: 8px groove goldenrod;
  border-radius: 5px;
 }

 .mat-container {
  /* background: rgba(133, 133, 133, 0.5); */
  text-align: center !important;
  display: flex;
  justify-content: center;
  /* width: 960px; */
}


 .log {
  background-color: red;
  width: 960px;
  text-align: center;
  align-items: center;
 }


@keyframes Block-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.em {
  font-weight: bold;
  font-family: 'Courier New', Courier, monospace;
}

.play{
  font-weight: bold;
  font-size: large;
  margin: 0.1em;
}

ul.mlist {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

.modal-body
{ 
    font-family:Arial, Helvetica, sans-serif;
    color: white;
    background-color: rgb(90, 130, 130);
}

.modal-content
{
  
    background-color: transparent;
}

.modal-footer
{
    background-color: darkgoldenrod !important;
    display: flex;
    justify-content: center !important;
    font-weight: bold;

}

.modal-header
{
  color: white;
    background-color: darkgoldenrod;
  
}
  