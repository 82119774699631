.heart1 {
    width: 100px;
    height: 100px;
    background: url("https://cssanimation.rocks/images/posts/steps/heart.png") no-repeat;
    background-position: 0 0;
    cursor: pointer;
    transition: background-position 1s steps(28);
    transition-duration: 0s;
}    
.heart2 {
    width: 100px;
    height: 100px;
    background: url("https://cssanimation.rocks/images/posts/steps/heart.png") no-repeat 0 0;
    background-position: 0px 0px;
    cursor: pointer;
    animation: mymove 1s steps(28) infinite;
}  
@keyframes mymove {
    50% {background-position: -2800px 0px;}
}
.is-active {
    transition-duration: 1s;
    background-position: -2800px 0px;
}

.fly1{
    width: 240px;
    height: 240px;
    background: url("/public/images/butterflysprite_01.png") no-repeat 0 0;
    background-position: 0px 0px;
    cursor: pointer;
    animation: myfly 0.5s steps(4) infinite;
} 

@keyframes myfly {
    50% {background-position: -960px 0px;}

}