.head {
    background-color:#fc7300;
    font-family:monospace;
    font-weight: bold;
    color: black;
}

.lrow:nth-child(even) {
    background-color:#bfdb38;
    font-family:monospace;
    color: black;
}

.lrow:nth-child(odd) {
    background-color:#1f8a70;
    font-family:monospace;
    color:black
}


.leaderBtn {
    padding: 5px !important;
    text-align: end;
}

.leaderBtn:hover {
    background-color: darkorange !important;
}