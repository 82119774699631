 .Canvas {
  text-align: center;
  align-items: center;
  margin: auto;
  margin-left: auto;
  margin-right: auto;

}



.noselect {
  
  -webkit-touch-callout: none; 
    -webkit-user-select: none; 
     -khtml-user-select: none; 
       -moz-user-select: none; 
        -ms-user-select: none; 
            user-select: none; 
                                  
}

.logs {
 
  width: 960px;
  text-align: center;
  align-items: center;
 }

 .Block {
    text-align: center;
    align-items: center;
    font-size: calc(18px + 2vmin);
    width: 100% !important;
    height: 100% !important;
    font-weight: bold;
    font-family: fantasy;
    padding:1px;
    border-radius: 5px;
    border: 3px groove wheat;

 }
 .Block:hover{
    border: 3px ridge wheat;
 }

 
 .bDiv{
  margin-top: 40px !important;
  text-align: center !important;
  display: flex;
  justify-content: center;
 }

 .bottomContainer{
  border-radius: 10px;
  background-color: peru;
  height: 70px;
  width: 256px;
  display: flex;

 }

 .mJar{
  border-radius: 10px;
  text-align: center !important;
  background-color: peru;
  height: 70px;
  display: flex;
  justify-content: center;

 }

 /* .Matrix {
  text-align: center;
  align-items: center;
  width: 300px !important;
  height: 400px !important;
  border: 5px;
  border-color: blueviolet;
 }  */

 .cap {
  margin-top: 3px !important;
  text-align: center;
  align-items: center;
  z-index: 1;
  width: 40px !important;
  height: 5px !important;
  background-color: transparent;
  background-image: url("/public/images/jarcap_01.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
}

 .jar {
  position: absolute;
  z-index: 50;
  margin-top: 5px !important;
  text-align: center;
  align-items: center;
  width: 40px !important;
  height:  55px !important;
  background-color: transparent;
  border-color: whitesmoke;
  border-width: 2px;
  background-image: url("/public/images/jar_01.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 33px;
  font-family: fantasy;
  vertical-align: bottom;

}

.scoreBox {
  display: flex;
  margin-top: 10px;
  font-size: 18px;
 
  height: 50px;
  font-family: fantasy;
  align-items: center !important;
  text-align: center !important;
  background-color: rgb(58, 43, 107,0.6);;
}

.score{
  width: 100%;
  color: white !important;
}

 .Nest {
  text-align: center;
  align-items: center;
  width: 50px  !important;
  height: 50px  !important;
  background-color: rgba(27, 10, 12, 0.5);  
  /* border-color: whitesmoke;
  border-width: 2px; */
  
}



.CBlock {
  text-align: center;
  align-items: center;
  width: 50px !important;
  height: 50px !important;  
 
}

.fly {
  background-image: url("/public/images/butterfly_01.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  border: 3px groove transparent;
  
}

 .Color1 {
  background-image: url("/public/images/planet_01.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
 
  
}

.Color2 {
  background-image: url("/public/images/planet_02.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}

.Color3 {
  background-image: url("/public/images/planet_03.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: fantasy;
}

.Color4 {
  background-image: url("/public/images/planet_04.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Color5 {
  background-image: url("/public/images/planet_05.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.Color6 {
  background-image: url("/public/images/planet_06.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Color7 {
  background-image: url("/public/images/planet_07.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Color8 {
  background-image: url("/public/images/planet_08.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.Color9 {
  background-image: url("/public/images/planet_09.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

  
  @keyframes Block-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  